// .swal-overlay {
//     // background-color: rgba(43, 165, 137, 0.45);
//   }
  .swal-modal {
    border: 3px solid green;
  }
  .swal-title {
    margin: 0px;
    font-size: 16px;
    color: green !important;
    margin-bottom: 28px;
  }
  .swal-button {
    padding: 7px 19px;
    border-radius: 2px;
    background-color: green;
    font-size: 12px;
    text-shadow: 0px -1px 0px rgba(0, 0, 0, 0.3);
  }
  .swal-button:focus{
    outline: none !important;
  }

$base-color: #3b6cb5;


.Login_Page_Container {
    height: 100vh;
    width: 100%;
    background-color: #DFF0FF !important;
   position: fixed  !important;
    .Left_Conteiner {
        width: 100%;
        height: 100vh;
        text-align: center;
        display: flex;
        justify-content: center;
        align-items: center;

        img {
            width: 60%;
            height: 60%;
        }
    }

    .Right_Conteiner {
        width: 100%;
        height: 100vh;
        text-align: center;
        display: flex;
        // justify-content: center;
        // background-color: white !important;
        align-items: center;

        .Login_Input_Container {
            background-color: white !important;
            padding: 22px;
            width: 80%;
            border-radius: 30px !important;
            display: flex;
            flex-direction: column;
            align-items: center !important;
            justify-content: start !important;
            overflow: scroll !important;
            justify-content: center !important;

            .Login_Special_Container {
                text-align: start !important;
            }

            .Header_image {
                display: flex;
                justify-content: center;
                width: 100%;

                .logo {
                    height: 48px;
                    width: 42px;
                    margin-right: 28px;
                }

                .logoText {
                    height: 50px;
                    width: 228px;
                }
            }

            h1 {
                margin-top: 20px;
                font-size: 32px !important;
                font-weight: 700;
                color: #292929;
                font-family: 'Poppins', sans-serif !important;
            }

            h2 {
                font-size: 14px;
                font-weight: 500;
                color: #9A9DA2;
            }

            .Login_Input_Boxes {
                display: flex !important;
                // background-color: red !important;
                text-align: start !important;

                .InputTitle {
                    font-size: 14px;
                    color: #263238;
                    font-family: 'Poppins', sans-serif !important;
                    padding-bottom: 2px;

                }

                .field {
                    height: 44px !important;
                    border-radius: 2px;

                }

                button {
                    border-radius: 2px;
                    height: 50px;
                    background-color: #0142DA;
                    border: 2px solid #64FFDB;
                    color: white;
                    font-weight: 700;
                    font-family: 'Poppins', sans-serif !important;
                }

            }

            .Register_Special_Container {
                text-align: start !important;
                padding: 80px 0 40px 0;

                .Register_Input_Boxes {
                    display: flex !important;
                    // background-color: red !important;
                    text-align: start !important;
                    .InputTitle {
                        font-size: 14px;
                        color: #263238;
                        font-family: 'Poppins', sans-serif !important;
                        padding-bottom: 2px;
                        margin-top: 14px !important;

                    }

                    .field {
                        height: 44px !important;
                        border-radius: 2px;
                    }


                    button {
                        border-radius: 2px;
                        height: 50px;
                        background-color: #0142DA;
                        border: 2px solid #64FFDB;
                        color: white;
                        font-weight: 700;
                        font-family: 'Poppins', sans-serif !important;
                    }
                }
            }
        }

        .Login_Input_Container::-webkit-scrollbar {
            width: 0px !important;
        }

        .field::placeholder {
            color: #9A9DA2;
            font-size: 14px !important;
        }
    }

    .SignUpMessage {
        display: flex;
        text-align: center;
        justify-content: center;
        align-items: center;
        margin-top: 10px;
        gap: 6px;

        span {
            font-size: 14px;

            &:nth-child(2) {
                font-size: 14px !important;
                font-weight: 600;
                color: #0142DA;
                cursor: pointer;
            }
        }
    }

    .showPass {
        span {
            font-size: 14px;
            color: #9A9DA2;
        }
    }
    .get_Otp{
        position: relative;
        button , span{
            position: absolute;
          
            border: none !important;
            font-size: 14px !important;
            font-weight: 600;
            cursor: pointer;
            background-color: transparent !important;
        }
        span{
            right: 18px !important;
            bottom: 6px !important;

        }
        button{
            color: #0142DA !important;
            right: 38px !important;
            bottom: -4px !important;
        }
    }
}